import { defineComponent } from 'vue';
// @ts-ignore
import VueMarkdown from 'vue3-markdown-it';
import './DocViewer.scss';
import { ModalBox, Icon } from '@/components/UI';
export default defineComponent({
    name: 'DocViewer',
    components: {
        VueMarkdown,
        Icon,
        ModalBox
    },
    props: {
        doc: {
            type: String,
            required: true
        }
    },
    data: () => ({
        visible: false
    }),
    computed: {
        source() {
            const module = require(`@/docs/${this.doc}.md`);
            if (module.default)
                return module.default;
            return 'doc not found';
        }
    }
});
