import { Table, Alert, ModalBox, DropDown, DropDownItem } from '@/components/UI';
import { EditHardwareConfig, HardwareInfoAction } from './HardwareActionButtons';
import { helpers } from '@/store/helpers';
import { HealthStatus } from '@/types/hardware';
import { defineComponent } from 'vue';
import useHardware from '@/compositions/useHardware';
import { useToast } from 'vue-toastification';
import useMobile from '@/compositions/useMobile';
const voltageMapping = {
    '0x1': 'Under Voltage',
    '0x2': 'Arm Frequency Capped',
    '0x4': 'Currently Throttled',
    '0x8': 'Soft Temperature Limit Active',
    '0x10000': 'Under Voltage has Occured',
    '0x20000': 'Arm Frequency Capped has Occured',
    '0x40000': 'Throttling has Occured',
    '0x80000': 'Soft Temperature Limit has Occured'
};
export default defineComponent({
    name: 'HardwareTable',
    components: {
        EditHardwareConfig,
        HardwareInfoAction,
        Table,
        Alert,
        ModalBox,
        DropDown,
        DropDownItem
    },
    props: {
        filter: {
            type: String,
            required: true
        }
    },
    setup: () => ({
        ...useHardware(),
        ...useMobile(),
        toast: useToast()
    }),
    data: () => ({
        polling: false,
        selected: '',
        vncloading: {},
        upgradeVisible: false,
        restartVisible: false,
    }),
    computed: {
        fields() {
            return this.isMobile ? [
                { key: 'name', name: 'Name', sortable: true },
                { key: 'status', name: 'status', sortable: true },
                { key: 'actions', name: 'Actions', sortable: false }
            ] : [
                { key: 'id', name: 'Id', sortable: true },
                { key: 'name', name: 'Name', sortable: true },
                { key: 'uptime', name: 'Uptime', sortable: true },
                { key: 'version', name: 'Version', sortable: true },
                { key: 'cpu', name: 'CPU', sortable: true },
                { key: 'disk', name: 'Disk', sortable: true },
                { key: 'memory', name: 'Memory', sortable: true },
                { key: 'roundtrip', name: 'Roundtrip', sortable: true },
                { key: 'ip', name: 'PI', sortable: true },
                { key: 'temp', name: 'temp', sortable: true },
                { key: 'voltage', name: 'voltage', sortable: true },
                { key: 'vpn', name: 'VPN', sortable: true },
                { key: 'actions', name: 'Actions', sortable: false }
            ];
        },
        hardware() {
            const hardwarelist = [];
            Object.values(this.rpi).forEach(pi => {
                const hw = this.updateTableItem(pi);
                hardwarelist.push(hw);
            });
            Object.values(this.hardwareConfig).forEach(pi => {
                if (!pi || !pi.id || this.rpi[pi.id])
                    return;
                // @ts-ignore
                const data = this.__makeOfflineTableItem(pi);
                if (this.filter === 'all') {
                    hardwarelist.push(data);
                }
                else if (this.filter === 'online' && pi.alwaysonline) {
                    data._rowVariant = 'danger';
                    hardwarelist.push(data);
                }
            });
            return hardwarelist;
        }
    },
    methods: {
        setOfflineConfig(id) {
            this.selected = id;
            this.upgradeVisible = true;
        },
        versionToInt(version) {
            return Number.parseInt(version.replace('.', '00').replace('.', '00'), 10);
        },
        showRestartPopup(id) {
            this.selected = id;
            this.restartVisible = true;
        },
        __makeOfflineTableItem(pi) {
            var _a;
            const data = { ...pi };
            data.id = pi.id;
            data.uptime = '0';
            data.version = 'unknown';
            data.cpu = '0';
            data.disk = '0';
            data.memory = '0';
            data.roundtrip = 'offline';
            data.ip = (_a = pi.last_ip) !== null && _a !== void 0 ? _a : 'unknown';
            data.vpn = 'offline';
            data.status = 'offline';
            data._rowVariant = 'secondary';
            return data;
        },
        async openVNC(pi) {
            this.vncloading[pi.id] = true;
            this.$forceUpdate();
            const id = pi.vpn.split('.')[3];
            try {
                await this.$store.direct.dispatch.hardware.vnc(pi.id);
                window.open(`https://dekluis.ddns.net/VNC/VNC.html?id=${id}`, '_blank');
                this.vncloading[pi.id] = false;
                this.$forceUpdate();
            }
            catch (error) {
                this.toast.error(`Could not start VNC, ${error}`);
            }
        },
        async restart() {
            const success = await this.$store.direct.dispatch.hardware.softreset({ rpi: this.selected });
            if (success) {
                this.toast.success(`Pi is restarting`);
            }
            else {
                this.toast.error(`Could not restart pi`);
            }
            this.restartVisible = false;
        },
        async updateSoftware() {
            try {
                await this.$store.direct.dispatch.hardware.upgrade(this.selected);
                this.toast.success(`Pi is upgrading`);
                this.upgradeVisible = false;
            }
            catch (error) {
                this.toast.error(`Could not upgrade pi, ${error}`);
            }
        },
        voltageToTooltip(voltage) {
            const mask = Number.parseInt(voltage);
            let tooltip = '';
            for (const [key, value] of Object.entries(voltageMapping)) {
                if (mask & Number.parseInt(key))
                    tooltip += value + '\n';
            }
            if (tooltip === '')
                tooltip = 'OK';
            return tooltip;
        },
        updateTableItem(pi) {
            // @ts-ignore
            const hw = { ...pi };
            hw.uptime = helpers.secondsToTime(pi.uptime);
            hw.cpu = pi.cpu + '%';
            hw.disk = pi.diskspace + '%';
            hw.roundtrip = 'unknown';
            hw.state = 'online';
            hw.voltagetooltip = this.voltageToTooltip(hw.voltage);
            if (pi.roundtrip !== undefined && pi.roundtrip < 100)
                hw.roundtrip = Math.round(pi.roundtrip * 1000) + 'ms ';
            hw.memory = Math.floor(pi.mem * 10240) / 10 + 'MB';
            if (hw.status === HealthStatus.UNHEALTHY)
                hw._rowVariant = 'danger';
            if (hw.status === HealthStatus.WARNING)
                hw._rowVariant = 'warning';
            return hw;
        }
    }
});
