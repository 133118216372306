import { defineComponent } from 'vue';
export default defineComponent({
    name: 'PiSummary',
    props: {
        piid: {
            type: String,
            required: true
        }
    },
    computed: {
        config() {
            return this.$store.direct.state.hardware.config[this.piid];
        },
        status() {
            return this.$store.direct.state.hardware.status.rpi[this.piid];
        }
    },
    mounted() {
        this.$store.direct.dispatch.hardware.subscribe(this.piid);
    },
    destroyed() {
        this.$store.direct.dispatch.hardware.unsubscribe(this.piid);
    }
});
