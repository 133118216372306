import { HealthStatus } from '@/types/hardware';
import { helpers } from '@/store/helpers';
import { defineComponent } from 'vue';
import useHardware from '@/compositions/useHardware';
import { Table } from '@/components/UI';
import useMobile from '@/compositions/useMobile';
const variants = {
    [HealthStatus.UNKNOWN]: '',
    [HealthStatus.HEALTHY]: '',
    [HealthStatus.UNHEALTHY]: 'danger',
    [HealthStatus.WARNING]: 'warning',
};
export default defineComponent({
    name: 'ServerStatus',
    components: {
        Table
    },
    setup: () => ({
        ...useHardware(),
        ...useMobile()
    }),
    computed: {
        serverField() {
            return [{
                    cpu: `${this.server.cpu}%`,
                    mem: `${this.server.mem}%`,
                    diskspace: `${this.server.diskspace}%`,
                    ip: this.server.ip,
                    vpn: this.server.vpn,
                    uptime: helpers.secondsToTime(this.server.uptime),
                    _rowVariant: variants[this.server.status]
                }];
        },
        fields() {
            return [
                { key: 'uptime', name: 'Uptime' },
                { key: 'cpu', name: 'CPU' },
                { key: 'mem', name: 'Memory' },
                { key: 'diskspace', name: 'Diskspace' },
                { key: 'ip', name: 'IP' },
                { key: 'vpn', name: 'VPN' }
            ];
        }
    }
});
