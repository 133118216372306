import { HealthStatus } from '@/types/hardware';
import DocViewer from '@/components/General/DocViewer';
import { Table, Button } from '@/components/UI';
import { defineComponent } from 'vue';
import useHardware from '@/compositions/useHardware';
import useMobile from '@/compositions/useMobile';
// @ts-ignore
import Popper from 'vue3-popper';
import './ServiceStatusTable.scss';
const variants = {
    [HealthStatus.UNKNOWN]: '',
    [HealthStatus.HEALTHY]: '',
    [HealthStatus.UNHEALTHY]: 'danger',
    [HealthStatus.WARNING]: 'warning',
};
export default defineComponent({
    name: 'ServiceStatusTable',
    components: {
        DocViewer,
        Popper,
        Table,
        Button
    },
    setup: () => ({
        ...useHardware(),
        ...useMobile()
    }),
    data: () => ({
        updating: false
    }),
    computed: {
        serviceField() {
            const services = [];
            Object.values(this.services).forEach(service => {
                services.push({
                    cpu: `${service.cpu}%`,
                    mem: `${service.mem}%`,
                    running: `${service.running}`,
                    name: service.name,
                    versions: service.versions,
                    doc: service.name.includes('beatout-room') ? 'beatout-room' : service.name,
                    _rowVariant: variants[service.status]
                });
            });
            return services;
        },
        fields() {
            return [
                { key: 'name', name: 'Name ' },
                { key: 'running', name: 'Running' },
                { key: 'cpu', name: 'CPU' },
                { key: 'mem', name: 'Memory' },
                { key: 'versions', name: 'Versions' },
                { key: 'info', name: 'Info' }
            ];
        }
    },
    methods: {
        async updateService(name, version) {
            this.updating = true;
            await this.$store.direct.dispatch.software.updateSoftware({ service: name, version });
            this.updating = false;
        },
        highestVersion(versions) {
            if (Object.keys(versions).length === 0)
                return null;
            // eslint-disable-next-line max-len
            return Object.values(versions).reduce((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }) > 0 ? a : b, '');
        }
    }
});
