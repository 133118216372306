import { defineComponent } from 'vue';
import PiSummary from '../../PiSummary';
import { DropDownItem, ModalBox } from '@/components/UI';
export default defineComponent({
    name: 'HardwareInfoAction',
    components: {
        PiSummary,
        DropDownItem,
        ModalBox
    },
    props: {
        piid: {
            type: String,
            required: true
        }
    },
    data: () => {
        return {
            visible: false
        };
    },
    computed: {
        id() {
            return `${this.piid}_info`;
        }
    },
    methods: {
        open() {
            this.visible = true;
        }
    }
});
