/* eslint-disable max-lines */
import { defineComponent } from 'vue';
import { Sensor, SensorType } from '@/types/hardware';
import { Card, CheckBox, Selector, Alert } from '@/components/UI';
export default defineComponent({
    name: 'EditHardware',
    components: {
        Card,
        CheckBox,
        Selector,
        Alert
    },
    props: {
        modelValue: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        selectedType: SensorType.Button,
        audiooptions: [
            { value: '2', text: 'HDMI' },
            { value: '1', text: 'Jack' }
        ],
        ledstripoptions: [
            { value: 10, text: '10' },
            { value: 12, text: '12' },
            { value: 18, text: '18' },
            { value: 21, text: '21' }
        ],
        options: [
            { value: SensorType.Button, text: 'Button' },
            { value: SensorType.Led, text: 'Led' },
            { value: SensorType.Webpage, text: 'WebPage' },
            { value: SensorType.RFIDReader, text: 'RFID Reader' },
            { value: SensorType.LightSensor, text: 'Light Sensor' },
            { value: SensorType.TestButton, text: 'Test Button' },
            { value: SensorType.TestLed, text: 'Test Led' },
            { value: SensorType.Keyboard, text: 'Keyboard' },
            { value: SensorType.TestRFIDReader, text: 'Test RFID Reader' },
            { value: SensorType.LedStrip, text: 'Led Strip' },
            { value: SensorType.SpiderWeb, text: 'Spider Web' }
        ]
    }),
    computed: {
        channels: {
            get() {
                return this.modelValue.message_channels.join();
            },
            set(newValue) {
                this.modelValue.message_channels = newValue.replace(' ', '').split(',');
            }
        }
    },
    mounted() {
        if (this.modelValue.alwaysonline === undefined) {
            this.modelValue.alwaysonline = false;
        }
        if (this.modelValue.audiooutput === undefined) {
            this.modelValue.audiooutput = 2;
        }
    },
    methods: {
        addNewSensor() {
            let data = {};
            const type = this.selectedType;
            if (type === SensorType.Button || type === SensorType.Led) {
                data = { pin: 0, board: 0 };
            }
            else if (type === SensorType.Webpage) {
                data = { url: 'http://www.google.be' };
            }
            else if (type === SensorType.LightSensor) {
                data = { pin: 0, lower: 0, higher: 0 };
            }
            else if (type === SensorType.TestButton) {
                data = { button: 'random' };
            }
            else if (type === SensorType.LedStrip) {
                data = { pin: 18, board: 0 };
            }
            this.modelValue.sensors.push(new Sensor({ id: 'new', type, data }));
        },
        removeSensor(key) {
            this.modelValue.sensors.splice(key, 1);
        },
        pinErrorMessage(code, { pin, board, sensorid, type }, chosen) {
            let blacklist = [7, 8, 9, 10, 11, 16, 17, 18, 19, 20, 21];
            if (type === SensorType.LedStrip) {
                blacklist = [7, 8, 9, 10, 11, 16, 17, 19, 20];
            }
            if (pin === code && (pin < 0 || pin > 26)) {
                return `Pin ids should be between 0 and 26 on board 0 (${sensorid}); `;
            }
            if (pin !== code && (pin < 0 || pin > 7)) {
                return `Pin ids should be between 0 and 7 on board > 0 (${sensorid}); `;
            }
            if (blacklist.includes(code)) {
                return `Pin ${pin} is not an allowed port on board 0 (${sensorid}); `;
            }
            if (chosen.includes(code)) {
                return `Pin ${pin}/${board} is used twice ( ${sensorid}); `;
            }
            return '';
        },
        getConflicts() {
            const chosen = [];
            let errorMessage = '';
            for (const sensor of this.modelValue.sensors) {
                if (sensor.data.hasOwnProperty('pin')) {
                    const code = this.pinToCode(sensor);
                    sensor.data.pin = Number.parseInt(sensor.data.pin, 10);
                    sensor.data.board = Number.parseInt(sensor.data.board, 10);
                    const pin = sensor.data.pin;
                    const board = sensor.data.board;
                    const sensorid = sensor.id;
                    const type = sensor.type;
                    const pinmessage = this.pinErrorMessage(code, { pin, board, sensorid, type }, chosen);
                    if (pinmessage !== '') {
                        errorMessage += pinmessage;
                    }
                    else {
                        chosen.push(code);
                    }
                }
            }
            return errorMessage;
        },
        pinToCode(sensor) {
            const data = sensor.data;
            let board = 0;
            if (data.hasOwnProperty('board')) {
                board = data.board;
            }
            let code = board * 100;
            code += data.pin;
            if (board > 0 && sensor.type === 'button') {
                code += 1000000;
            }
            return code;
        }
    }
});
