// @ts-ignore
import { useMq } from 'vue3-mq';
import { computed } from 'vue';
const useMobile = () => {
    const mq = useMq();
    return {
        isMobile: computed(() => mq.sm)
    };
};
export default useMobile;
