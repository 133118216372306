import { defineComponent } from 'vue';
import EditHardware from '../../EditHardware';
import { ModalBox, DropDownItem } from '@/components/UI';
import { cloneDeep } from 'lodash';
export default defineComponent({
    name: 'EditHardwareConfig',
    components: {
        EditHardware,
        ModalBox,
        DropDownItem
    },
    props: {
        piid: {
            type: String,
            required: true
        }
    },
    data: () => ({
        editConfig: null,
        visible: false
    }),
    computed: {
        config() {
            return this.$store.direct.state.hardware.config[this.piid];
        }
    },
    mounted() {
        this.$store.direct.dispatch.hardware.subscribe(this.piid);
    },
    beforeDestroy() {
        this.$store.direct.dispatch.hardware.unsubscribe(this.piid);
    },
    methods: {
        startEdit() {
            if (this.config === undefined)
                return;
            this.editConfig = cloneDeep(this.config);
            this.visible = true;
        },
        saveConfig() {
            if (this.editConfig) {
                this.$store.direct.dispatch.hardware.update({ ...this.editConfig, id: this.piid });
            }
        }
    }
});
