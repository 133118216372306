import { defineComponent } from 'vue';
import EditITTT from '../../EditITTT';
import { ModalBox, DropDownItem } from '@/components/UI';
export default defineComponent({
    name: 'ITTTHardwareButton',
    components: {
        EditITTT,
        ModalBox,
        DropDownItem
    },
    props: {
        piid: {
            type: String,
            required: true
        }
    },
    data: () => ({
        editConfig: null,
        visible: false
    }),
    computed: {
        config() {
            // FIXME is a performance issue
            return this.$store.direct.state.hardware.config[this.piid];
        }
    },
    mounted() {
        this.$store.direct.dispatch.hardware.subscribe(this.piid);
    },
    beforeDestroy() {
        this.$store.direct.dispatch.hardware.unsubscribe(this.piid);
    },
    methods: {
        startEdit() {
            this.visible = true;
            this.editConfig = JSON.parse(JSON.stringify(this.config));
        },
        saveConfig() {
            this.visible = false;
            this.$store.direct.dispatch.hardware.update({ ...this.editConfig, id: this.piid });
        }
    }
});
