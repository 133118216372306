import store from '@/store';
import { computed, onMounted, onUnmounted } from 'vue';
const useHardware = () => {
    onMounted(async () => store.dispatch.hardware.subscribe());
    onUnmounted(async () => store.dispatch.hardware.unsubscribe());
    return {
        rpi: computed(() => store.state.hardware.status.rpi),
        hardwareConfig: computed(() => store.state.hardware.config),
        server: computed(() => store.state.hardware.status.server),
        services: computed(() => store.state.hardware.status.services),
        health: computed(() => store.state.hardware.status.status)
    };
};
export default useHardware;
