import { defineComponent } from 'vue';
import { SensorType } from '@/types/hardware';
export default defineComponent({
    name: 'EditITTT',
    props: {
        modelValue: {
            type: Object,
            required: true
        },
        piId: {
            type: String,
            required: true
        }
    },
    data: () => ({
        ifsensor: null,
        ifaction: null,
        ofsensor: null,
        ofaction: null
    }),
    computed: {
        ifsensors() {
            const sensors = [{ value: null, text: 'Select input sensor' }];
            this.getSensors([SensorType.Button, SensorType.RFIDReader, SensorType.TestButton]).forEach(sensor => {
                sensors.push({ value: sensor.id, text: sensor.id });
            });
            return sensors;
        },
        ofsensors() {
            const sensors = [{ value: null, text: 'Select output sensor' }];
            this.getSensors([SensorType.Led, SensorType.TestLed]).forEach(sensor => {
                sensors.push({ value: sensor.id, text: sensor.id });
            });
            return sensors;
        },
        ifsensoractions() {
            const actions = [{ value: null, text: 'Select Action' }];
            if (this.ifsensor === null)
                return actions;
            this.getActions(this.ifsensor.id).forEach(action => {
                actions.push({ value: action, text: action });
            });
            return actions;
        },
        ofsensoractions() {
            const actions = [{ value: null, text: 'Select Action' }];
            if (this.ofsensor === null)
                return actions;
            this.getActions(this.ofsensor.id).forEach(action => {
                actions.push({ value: action, text: action });
            });
            return actions;
        },
        addDisabled() {
            return this.ifsensor === null || this.ofsensor === null || this.ifaction === null || this.ofaction === null;
        }
    },
    methods: {
        getSensors(types) {
            return this.modelValue.sensors.filter(sensor => types.includes(sensor.type));
        },
        getActions(sensorid) {
            const sensor = this.modelValue.sensors.find(sensor => sensor.id === sensorid);
            if (sensor === undefined)
                return [];
            if (sensor.type === 'button' || sensor.type === 'testbutton') {
                return ['clicked', 'released'];
            }
            else if (sensor.type === 'rfidreader') {
                return ['read'];
            }
            else if (sensor.type === 'led' || sensor.type === 'testled') {
                return ['on', 'off'];
            }
            return [];
        },
        addRule() {
            if (this.ifsensor === null || this.ofsensor === null)
                return;
            if (this.ifaction === null || this.ofaction === null)
                return;
            const rule = {
                // @ts-ignore
                if: { sensor: this.ifsensor.id, action: this.ifaction },
                // @ts-ignore
                then: { sensor: this.ofsensor.id, action: this.ofaction },
                id: Math.random().toString(36).slice(7)
            };
            if (this.modelValue.ittt === undefined) {
                this.modelValue.ittt = [];
            }
            this.modelValue.ittt.push(rule);
            this.ofsensor = null;
            this.ifsensor = null;
            this.ifaction = null;
            this.ofaction = null;
        },
        delRule(id) {
            if (this.modelValue.ittt === undefined)
                return;
            const index = this.modelValue.ittt.findIndex(ittt => ittt.id === id);
            if (index !== -1) {
                this.modelValue.ittt.splice(index, 1);
            }
        }
    }
});
